/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import Admin_Profile from '../../../Components/ExtraComponents/Profile'
import Content from "../../../Components/Dashboard/Content/Content"

const Profile = () => {
  return (
    <div>
        <Admin_Profile route=""/>
        </div>
  )
}

export default Profile

