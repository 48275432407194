/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { admin_sidebar, supper_admin_sidebar, sub_admin_sidebar, Client } from './Nav_Config'
import { Signal, Users, Wrench, Frame, CandlestickChart, Activity, WalletCards, HelpingHand, FolderClock, LayoutDashboard, Building2, Copyright, Repeat2, ArrowRightLeft, ScatterChart, Boxes  , Rocket, Paintbrush, Vote,Info } from 'lucide-react';
import Test from "../../../test"
import html2canvas from 'html2canvas';
import $ from "jquery";
import Logo from '../Header/Logo';
import { Get_Sub_Admin_Permissions } from '../../../ReduxStore/Slice/Subadmin/Subadminslice';
import { useDispatch, useSelector } from "react-redux";





const Sidebar = ({ ShowSidebar }) => {

    const location = useLocation()
    const dispatch = useDispatch()



    const roles = JSON.parse(localStorage.getItem('user_role'))
    const gotodashboard = JSON.parse(localStorage.getItem('gotodashboard'))
    const user_role_goTo = JSON.parse(localStorage.getItem('user_role_goTo'))
    const user_ID = JSON.parse(localStorage.getItem("user_details")).user_id


    const [getPermissions, setGetPermissions] = useState([])





    //  GET SUBADMIN PERMISSION
    const data2 = async () => {
        if (roles === 'SUBADMIN') {
            await dispatch(Get_Sub_Admin_Permissions({ id: user_ID })).unwrap()
                .then((response) => {
                    if (response.status) {
                        setGetPermissions(response.data[0])

                    }
                })
        }
    }
    useEffect(() => {
        data2()
    }, [])







    return (
        <div>

            <div className="deznav" >
                <div className="deznav-scroll">


                    <ul className="metismenu" id="menu">
                        <div className='sidebar-logo'>

                            <Logo />

                        </div>
                        {
                            gotodashboard != null ? user_role_goTo === "USER" ? Client && Client.map((item) => {
                                return <>
                                    <li className={`${location.pathname === item.route && item.route ? 'mm-active' : ""}`}>
                                        {item.Data.length > 0 ? <>

                                            <Link
                                                className="has-arrow "
                                                // href="javascript:void()"
                                                aria-expanded="false"
                                            >
                                                <IconComponent key={item.id} icon={item.Icon} />

                                                <span className="nav-text mx-2">{item.name}</span>
                                            </Link>
                                        </> : ""}
                                        <ul aria-expanded="false">
                                            {item.Data.length > 0 ?
                                                item.Data.map((nested_item) => {
                                                    return <>
                                                        <li className={`${location.pathname === item.route && item.route ? 'mm-active' : ""}`}>
                                                            <Link to={nested_item.route}>{nested_item.name}</Link>
                                                        </li>
                                                    </>
                                                })
                                                : ""}
                                        </ul>
                                    </li>
                                    {item.Data.length === 0 ? <>
                                        <li className={`${location.pathname === item.route && item.route ? 'mm-active' : ""}`}>
                                            <Link to={item.route} className="" aria-expanded="false">
                                                <IconComponent key={item.id} icon={item.Icon} />

                                                <span className="nav-text mx-2">{item.name}</span>
                                            </Link>
                                        </li>
                                    </> : ""}


                                </>
                            }) : user_role_goTo === "SUBADMIN" ? sub_admin_sidebar && sub_admin_sidebar.map((item) => {
                                return <>
                                    <li className={`${location.pathname === item.route && item.route ? 'mm-active' : ""}`}>
                                        {item.Data.length > 0 ? <>
                                            <Link
                                                className="has-arrow "
                                                aria-expanded="false"
                                            >
                                                <IconComponent key={item.id} icon={item.Icon} />
                                                <span className="nav-text mx-2">{item.name}</span>
                                            </Link>
                                        </> : ""}
                                        <ul aria-expanded="false">
                                            {item.Data.length > 0 ?
                                                item.Data.map((nested_item) => {
                                                    return <>
                                                        <li className={`${location.pathname === item.route && item.route ? 'mm-active' : ""}`}>
                                                            <Link to={nested_item.route}>{nested_item.name}</Link>
                                                        </li>
                                                    </>
                                                })
                                                : ""}
                                        </ul>
                                    </li>
                                    {item.Data.length === 0 ? <>
                                        <li className={`${location.pathname === item.route && item.route ? 'mm-active' : ""}`}>
                                            <Link to={item.route} className="" aria-expanded="false">
                                                <IconComponent key={item.id} icon={item.Icon} />

                                                <span className="nav-text mx-2">{item.name}</span>
                                            </Link>
                                        </li>
                                    </> : ""}


                                </>
                            }) : "" :
                                roles === 'ADMIN' ? admin_sidebar && admin_sidebar.map((item) => {
                                    return <>
                                        <li className={`${location.pathname === item.route && item.route ? 'mm-active' : ""}`}>
                                            {item.Data.length > 0 ? <>
                                                <a
                                                    className="has-arrow"
                                                    aria-expanded={location.pathname === item.route && item.route ? true : false}
                                                // className={`has-arrow `}
                                                >
                                                    <IconComponent key={item.id} icon={item.Icon} className='mx-2' />
                                                    <span className="nav-text">{item.name}</span>
                                                </a>
                                            </> : ""}
                                            <ul aria-expanded='false'>
                                                {item.Data.length > 0 ?
                                                    item.Data.map((nested_item) => {
                                                        return <>
                                                            <li className={`${location.pathname === item.route && item.route ? 'mm-active' : ""}`}>
                                                                <Link to={nested_item.route}>{nested_item.name}</Link>
                                                            </li>
                                                        </>
                                                    })
                                                    : ""}
                                            </ul>
                                        </li>
                                        {item.Data.length === 0 ? <>

                                            <li className={`${location.pathname === item.route && item.route ? 'mm-active' : ""}`}>
                                                <Link to={item.route} className="" aria-expanded="false">
                                                    <IconComponent key={item.id} icon={item.Icon} />
                                                    <span className="nav-text">{item.name}</span>
                                                </Link>
                                            </li>
                                        </> : ""}


                                    </>
                                }) : roles === 'SUPERADMIN' ? supper_admin_sidebar && supper_admin_sidebar.map((item) => {
                                    return <>
                                        <li className={`${location.pathname === item.route && item.route ? 'mm-active' : ""}`}>
                                            {item.Data.length > 0 ? <>

                                                <Link
                                                    className="has-arrow "
                                                    href="javascript:void()"
                                                    aria-expanded="false"
                                                    to={item.route}
                                                >
                                                    <IconComponent key={item.id} icon={item.Icon} className='mx-2' />
                                                    <span className="nav-text mx-2 mm-active">{item.name}</span>
                                                </Link>
                                            </> : ""}
                                            <ul aria-expanded="false" >
                                                {item.Data.length > 0 ?
                                                    item.Data.map((nested_item) => {
                                                        return <>
                                                            <li className={`${location.pathname === item.route && item.route ? 'mm-active' : ""}`}>
                                                                <Link to={nested_item.route}>{nested_item.name}</Link>
                                                            </li>
                                                        </>
                                                    })
                                                    : ""}
                                            </ul>
                                        </li>
                                        {item.Data.length === 0 ? <>
                                            <li className={`${location.pathname === item.route && item.route ? 'mm-active' : ""}`}>
                                                <Link to={item.route} className="" aria-expanded="false">
                                                    <IconComponent key={item.id} icon={item.Icon} />

                                                    <span className="nav-text mx-2">{item.name}</span>
                                                </Link>
                                            </li>
                                        </> : ""}


                                    </>
                                }) :
                                    roles === 'SUBADMIN' ? sub_admin_sidebar && sub_admin_sidebar.map((item) => {
                                        return <>
                                            {item.route === "/subadmin/tradehistory" && getPermissions && getPermissions.trade_history_old === 1 ? <>

                                                <li className={`${location.pathname === item.route && item.route ? 'mm-active' : ""}`}>
                                                    {item.Data.length > 0 ? <>

                                                        <Link
                                                            className="has-arrow "
                                                            // href="javascript:void()"
                                                            aria-expanded="false"
                                                        >
                                                            <IconComponent key={item.id} icon={item.Icon} />

                                                            <span className="nav-text  mx-2">{item.name}</span>
                                                        </Link>
                                                    </> : ""}
                                                    <ul aria-expanded="false">
                                                        {item.Data.length > 0 ?
                                                            item.Data.map((nested_item) => {
                                                                return <>
                                                                    <li className={`${location.pathname === item.route && item.route ? 'mm-active' : ""}`}>
                                                                        <Link to={nested_item.route}>{nested_item.name}</Link>
                                                                    </li>
                                                                </>
                                                            })
                                                            : ""}
                                                    </ul>
                                                </li>
                                            </> : ""}

                                            {item.Data.length === 0 ? <>
                                                {item.route === "/subadmin/tradehistory" && getPermissions && getPermissions.trade_history_old === 0 ? '' :
                                                <li className={`${location.pathname === item.route && item.route ? 'mm-active' : ""}`}>
                                                    <Link to={item.route} className="" aria-expanded="false">
                                                        <IconComponent key={item.id} icon={item.Icon} />
                                                        {/* <i className="flaticon-013-checkmark" /> */}
                                                        <span className="nav-text mx-2">{item.name}</span>
                                                    </Link>
                                                </li>
                                                }

                                            </>


                                                : ""}


                                        </>
                                    }) :
                                        roles === "USER" ? Client && Client.map((item) => {
                                            return <>
                                                <li className={`${location.pathname === item.route && item.route ? 'mm-active' : ""}`}>
                                                    {item.Data.length > 0 ? <>

                                                        <Link
                                                            className="has-arrow "
                                                            // href="javascript:void()"
                                                            aria-expanded="false"
                                                        >
                                                            <IconComponent key={item.id} icon={item.Icon} />

                                                            <span className="nav-text mx-2">{item.name}</span>
                                                        </Link>
                                                    </> : ""}
                                                    <ul aria-expanded="false">
                                                        {item.Data.length > 0 ?
                                                            item.Data.map((nested_item) => {
                                                                return <>
                                                                    <li className={`${location.pathname === item.route && item.route ? 'mm-active' : ""}`}>
                                                                        <Link to={nested_item.route}>{nested_item.name}</Link>
                                                                    </li>
                                                                </>
                                                            })
                                                            : ""}
                                                    </ul>
                                                </li>
                                                {item.Data.length === 0 ? <>
                                                    <li className={`${location.pathname === item.route && item.route ? 'mm-active' : ""}`}>
                                                        <Link to={item.route} className="" aria-expanded="false">
                                                            <IconComponent key={item.id} icon={item.Icon} />

                                                            <span className="nav-text mx-2">{item.name}</span>
                                                        </Link>
                                                    </li>
                                                </> : ""}


                                            </>
                                        })
                                            : ""
                        }



                    </ul>
                </div>
            </div>
        </div>

    )
}

export default Sidebar






const IconComponent = ({ icon }) => {
    // Render the icon based on the provided icon name
    const renderIcon = () => {
        switch (icon) {
            case 'Signal':
                return <Signal className='me-3' />;
            case 'Users':
                return <Users className='me-3' />;
            case 'Wrench':
                return <Wrench className='me-3' />;

            case 'Frame':
                return <Frame className='me-3' />;

            case 'CandlestickChart':
                return <CandlestickChart className='me-3' />;
            case 'Activity':
                return <Activity className='me-3' />;
            case 'WalletCards':
                return <WalletCards className='me-3' />;
            case 'HelpingHand':
                return <HelpingHand className='me-3' />;
            case 'FolderClock':
                return <FolderClock className='me-3' />;
            case 'LayoutDashboard':
                return <LayoutDashboard className='me-3' />;
            case 'Building2':
                return <Building2 className='me-3' />;
            case 'Copyright':
                return <Copyright className='me-3' />;
            case 'Repeat2':
                return <Repeat2 className='me-3' />;
            case 'Rocket':
                return <Rocket className='me-3' />;
            case 'ArrowRightLeft':
                return <ArrowRightLeft className='me-3' />;
            case 'ScatterChart':
                return <ScatterChart className='me-3' />;
            case 'Paintbrush':
                return <Paintbrush className='me-3' />;
            case 'Vote':
                return <Vote className='me-3' />;
            case 'Boxes ':
                return <Boxes  className='me-3' />;
                case 'Info':
                    return <Info  className='me-3' />;
    
            default:
                return null;
        }
    };

    return <>
        {renderIcon()}
    </>
};