/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Content from "../../../Components/Dashboard/Content/Content"

import { Link } from 'react-router-dom'

const Dashboard1 = ({ data }) => {

  let arr = [
    {
      index: 1,
      name: "Total  Client",
      value: data.data && data.data.total_client,
      icon: 'la la-users',
      route: "/admin/allclients"
    },
    {
      index: 2,
      name: "Admin Clients",
      value: data.data && data.data.admin_client,
      icon: 'la la-users',
      route: "/admin/allclients"
    },
    {
      index: 3,
      name: "Sub-Admin Clients",
      value: data.data && data.data.subadmin_client,
      icon: 'la la-users',
      route: "/admin/subadminclients"
    },
    {
      index: 4,
      name: "Total Live Account",
      value: data.data && data.data.total_live,
      icon: 'la la-users',
      route: '/admin/allclients?filter=2'
    },
    {
      index: 5,
      name: "Active  Live Account",
      value: data.data && data.data.total_active_live,
      icon: 'la la-users',
      route: "/admin/allclients?filter=21"
    },
    {
      index: 6,
      name: "Expired Live Account",
      value: data.data && data.data.total_expired_live,
      icon: 'la la-users',
      route: "/admin/allclients?filter=20"
    },

    {
      index: 7,
      name: "Total Demo Account",
      value: data.data && data.data.total_demo,
      icon: 'la la-users',
      route: "/admin/allclients?filter=1"
    },
    {
      index: 8,
      name: "Active Demo Account",
      value: data.data && data.data.total_active_demo,
      icon: 'la la-users',
      route: "/admin/allclients?filter=11"
    },
    {
      index: 9,
      name: "Expired Demo Account",
      value: data.data && data.data.total_expired_demo,
      icon: 'la la-users',
      route: "/admin/allclients?filter=10"
    },
    {
      index: 10,
      name: "2 Days Only Account",
      value: data.data && data.data.total_two_days,
      icon: 'la la-users',
      route: "/admin/allclients?filter=0"
    },
    {
      index: 11,
      name: "2 Days Active Account",
      value: data.data && data.data.total_active_two_days,
      icon: 'la la-users',
      route: "/admin/allclients?filter=01"
    },
    {
      index: 12,
      name: "2 Days Expired Account",
      value: data.data && data.data.total_expired_two_days,
      icon: 'la la-users',
      route: "/admin/allclients?filter=00"
    },
    {
      index: 13,
      name: "Total Licence",
      value: data.data && data.data.all_licence,
      icon: 'la la-users',
      route: "/admin/allLicence"
    }, {
      index: 14,
      name: "Remaining  Licence",
      value: data.data && data.data.remaining_licence,
      icon: 'la la-users',
      route: "/admin/allLicence"
    }, {
      index: 15,
      name: "Used  Licence",
      value: data.data && data.data.used_licence,
      icon: 'la la-users',
      route: "/admin/allLicence"
    },
    {
      index: 16,
      name: "Total Subadmin",
      value: data.data && data.data.total_Subadmin,
      icon: 'la la-users',
      route: "/admin/allsubadmins"
    },
  ]
  return <>
    <div className='theme-2-dashboard'>
      <div className='row'>
        {arr.map((item, index) => {
          return <>
            <div className="col-xl-4 col-lg-3 col-xxl-3 col-sm-6">
              <div className="rounded bg-primary overflow-hidden second-dash-card">
                <div className="card-body pb-0 pt-3">
                  <div className="row">
                    <div className="col-12">
                      <h5 className="text-white">{item.name}</h5>
                    </div>
                  <div className='d-flex p-3 align-content-center '>
                    <div className="col-6 text-end">
                      <h2 className="text-white mb-0">
                        {item.value}
                      </h2>
                      </div>
                      <div className="col-6 text-end">
                          <Link href="#" className="mb-2 text-white" to={item.route}>
                            <i className="fa-regular fa-eye pe-1" ></i>View</Link>
                    </div>
                  </div>

                  </div>
                </div>
                <div className="chart-wrapper">
                  {/* <img src='../assets/images/dash_icon/bar.png' className='w-100' /> */}
                </div>
              </div>
            </div>
          </>

        })}
      </div>
    </div>

  </>
}


export default Dashboard1




